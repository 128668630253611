var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"justify-center"},[(_vm.salesPersons)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.salesPersons,"item-key":"id","loading":_vm.loading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"teal","dark":""}},[_c('v-col',{attrs:{"cols":"4"}},[(_vm.periodInProgress.periodo)?_c('v-toolbar-title',[_vm._v("Metas para o "+_vm._s(_vm.periodInProgress.periodo)+" - "+_vm._s(_vm.periodInProgress.id))]):_vm._e(),_c('br'),(!_vm.periodInProgress.periodo)?_c('p',[_c('strong',[_vm._v(" Atenção! ")]),_vm._v("Não há período em andamento, cadastre um novo periodo clicando"),_c('router-link',{staticClass:"linkPeriod",attrs:{"to":"/period"}},[_vm._v(" aqui ")]),_vm._v("! ")],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":_vm.teams,"return-object":"","disabled":!_vm.periodInProgress.periodo,"item-text":"nome","append-outer-icon":"mdi-map","menu-props":"auto","hide-details":"","label":"Escolha um time","single-line":""},on:{"change":_vm.getGoalsSalesPerson},model:{value:(_vm.team),callback:function ($$v) {_vm.team=$$v},expression:"team"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"label":"Meta do time","hide-details":"auto","type":"number","disabled":_vm.salesPersons.length <= 0},on:{"keyup":_vm.calMetaIndividual},model:{value:(_vm.metaTime),callback:function ($$v) {_vm.metaTime=_vm._n($$v)},expression:"metaTime"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"label":"Dias","hide-details":"auto","type":"number"},on:{"change":_vm.calPrivisao},model:{value:(_vm.dias),callback:function ($$v) {_vm.dias=_vm._n($$v)},expression:"dias"}})],1)],1)]},proxy:true},{key:"item.replicarMeta",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","icon":"","color":"teal lighten-1"},on:{"click":function($event){(item.meta = item.metaAnterior), _vm.calTotalLimit()}}},[_c('v-icon',[_vm._v(" mdi-repeat ")])],1)]}},{key:"item.meta",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.meta,"large":"","persistent":""},on:{"update:returnValue":function($event){return _vm.$set(item, "meta", $event)},"update:return-value":function($event){return _vm.$set(item, "meta", $event)},"save":function($event){return _vm.save(item.meta)},"cancel":_vm.cancel,"open":_vm.open,"close":_vm.close},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"mt-4 title"},[_vm._v(" Meta do Vendedor ")]),_c('v-text-field',{attrs:{"label":"Regular","rules":[_vm.numberRule],"single-line":"","type":"number","counter":"","min":"0","autofocus":""},model:{value:(item.meta),callback:function ($$v) {_vm.$set(item, "meta", _vm._n($$v))},expression:"item.meta"}})]},proxy:true}],null,true)},[_c('div',[_vm._v(_vm._s(_vm.metaVendedor)+"/"+_vm._s(item.meta))])])]}},{key:"item.vendaPdia",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("formatPrevision")((item.meta / _vm.dias))))])]}},{key:"item.checkedGoal",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.getColor(item.meta)}},[_vm._v(" mdi-check-all ")])]}}],null,false,1421521245)},[_c('template',{slot:"body.append"},[_c('tr',{staticClass:"teal--text"},[_c('th',{staticClass:"title"},[_vm._v("Total")]),_c('th',{staticClass:"title"}),_c('th',{staticClass:"title"}),_c('th',{staticClass:"title"}),_c('th',{staticClass:"title"},[_vm._v(_vm._s(_vm.metaTime))]),_c('th',{staticClass:"title"},[_vm._v(_vm._s(_vm.dias))]),_c('th',{staticClass:"title"})]),_c('tr',{staticClass:"teal--text"},[_c('th',{staticClass:"title"}),_c('th',{staticClass:"title"}),_c('th',{staticClass:"title"}),_c('th',{staticClass:"title"}),_c('th',{staticClass:"title"},[_c('v-btn',{staticClass:" white--text",attrs:{"large":"","disabled":_vm.metaTime <= 0 || _vm.salesPersons.length <= 0,"outlined":"","depressed":"","color":"green"},on:{"click":_vm.salvarMetas}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save-all-outline")]),_vm._v(" Salvar")],1)],1),_c('th',{staticClass:"title"}),_c('th',{staticClass:"title"})])])],2):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }