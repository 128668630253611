<template>
  <v-container>
    <v-row class="justify-center">
      <v-data-table
        v-if="salesPersons"
        :headers="headers"
        :items="salesPersons"
        item-key="id"
        class="elevation-1"
        :loading="loading"
      >
        <template slot="body.append">
          <tr class="teal--text">
            <th class="title">Total</th>
            <th class="title"></th>
            <th class="title"></th>
            <th class="title"></th>
            <th class="title">{{ metaTime }}</th>
            <th class="title">{{ dias }}</th>
            <th class="title"></th>
          </tr>
          <tr class="teal--text">
            <th class="title"></th>
            <th class="title"></th>
            <th class="title"></th>
            <th class="title"></th>
            <th class="title">
              <v-btn
                large
                :disabled="metaTime <= 0 || salesPersons.length <= 0"
                outlined
                depressed
                color="green"
                class=" white--text"
                @click="salvarMetas"
                ><v-icon left>mdi-content-save-all-outline</v-icon>
                Salvar</v-btn
              >
            </th>
            <th class="title"></th>
            <th class="title"></th>
          </tr>
        </template>
        <template v-slot:top>
          <v-toolbar flat color="teal" dark>
            <v-col cols="4">
              <v-toolbar-title v-if="periodInProgress.periodo"
                >Metas para o {{ periodInProgress.periodo }} -
                {{ periodInProgress.id }}</v-toolbar-title
              >
              <br />
              <p v-if="!periodInProgress.periodo">
                <strong>
                  Atenção! </strong
                >Não há período em andamento, cadastre um novo periodo
                clicando<router-link class="linkPeriod" to="/period"
                  > aqui </router-link
                >!
              </p>
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="team"
                :items="teams"
                return-object
                :disabled="!periodInProgress.periodo"
                item-text="nome"
                append-outer-icon="mdi-map"
                menu-props="auto"
                hide-details
                label="Escolha um time"
                single-line
                @change="getGoalsSalesPerson"
              ></v-select>
            </v-col>
            <v-col cols="2">
              <v-text-field
                label="Meta do time"
                hide-details="auto"
                v-model.number="metaTime"
                type="number"
                :disabled="salesPersons.length <= 0"
                @keyup="calMetaIndividual"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                label="Dias"
                hide-details="auto"
                v-model.number="dias"
                type="number"
                @change="calPrivisao"
              ></v-text-field>
            </v-col>
          </v-toolbar>
        </template>
        <template v-slot:item.replicarMeta="{ item }">
          <v-btn
            text
            icon
            color="teal lighten-1"
            @click="(item.meta = item.metaAnterior), calTotalLimit()"
            ><v-icon>
              mdi-repeat
            </v-icon></v-btn
          >
        </template>
        <template v-slot:item.meta="{ item }">
          <v-edit-dialog
            :return-value.sync="item.meta"
            large
            persistent
            @save="save(item.meta)"
            @cancel="cancel"
            @open="open"
            @close="close"
          >
            <div>{{ metaVendedor }}/{{ item.meta }}</div>
            <template v-slot:input>
              <div class="mt-4 title">
                Meta do Vendedor
              </div>
              <v-text-field
                v-model.number="item.meta"
                label="Regular"
                :rules="[numberRule]"
                single-line
                type="number"
                counter
                min="0"
                autofocus
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:item.vendaPdia="{ item }">
          <div>{{ (item.meta / dias) | formatPrevision }}</div>
        </template>
        <template v-slot:item.checkedGoal="{ item }">
          <v-icon :color="getColor(item.meta)">
            mdi-check-all
          </v-icon>
        </template>
      </v-data-table>
    </v-row>
  </v-container>
</template>

<script>
import { TeamMixins, PeriodInProgress } from "../../mixins/";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

dayjs.extend(duration);
export default {
  mixins: [TeamMixins, PeriodInProgress],
  data: () => {
    return {
      headers: [
        { text: "ID", value: "id", class: "customHeader" },
        { text: "Nome", value: "nome", class: "customHeader" },
        { text: "Meta Anteriro", value: "metaAnterior", class: "customHeader" },
        { text: "Replicar Meta", value: "replicarMeta", class: "customHeader" },
        { text: "Meta", value: "meta", class: "customHeader" },
        { text: "Meta Por Dia", value: "vendaPdia", class: "customHeader" },
        { text: "Pronto", value: "checkedGoal", class: "customHeader" }
      ],
      teamGoal: {
        qtd: 0,
        name: "",
        team: 0
      },
      periodInProgress: {},
      loading: false,
      teams: [],
      team: {},
      salesPersons: [],
      metaTime: 0,
      metaVendedor: 0,
      resultado: 0,
      vendasPorDia: 0,
      dias: 0,
      resulTotal: 0,
      numberRule: v => {
        //if (!v.trim()) return true;
        if (!isNaN(parseFloat(v)) && v >= 0 && v <= 999) return true;
        return "O número deve estar entre 0 e 999";
      }
    };
  },
  created() {
    this.getPeriodInProgress();
    this.getTeams();
    this.dias = dayjs().daysInMonth();
  },
  mounted() {},
  computed: {},
  watch: {
    resultado: function(val) {
      this.salesPersons.forEach(function(salePerson) {
        salePerson.meta = val.toFixed(1);
      });
    }
  },
  filters: {
    formatPrevision(dias) {
      return dias.toFixed(1);
    }
  },
  methods: {
    calMetaIndividual() {
      this.resultado = this.metaTime / this.salesPersons.length;
      this.calPrivisao();
    },
    calPrivisao() {
      this.vendasPorDia = (this.resultado / this.dias).toFixed(1);
    },
    calTotalLimit() {
      let sum = 0;
      this.resulTotal = 0;
      for (let i = 0; i < this.salesPersons.length; i++) {
        const element = this.salesPersons[i];
        sum += parseFloat(element.meta);
      }
      this.resulTotal = sum;
      this.metaTime = Math.round(this.resulTotal).toFixed(1);
    },

    save(value) {
      if (typeof value == "number") {
        if (value >= 0) {
          this.calTotalLimit();
        } else {
          this.$toast.error("Entrada não permitida");
        }
      } else {
        this.$toast.error("Esse valor nã é permitido");
      }
    },
    cancel() {},
    open() {},
    close() {
    },

    async salvarMetas() {
      this.teamGoal.qtd = this.metaTime;
      this.teamGoal.name = this.team.nome;
      this.teamGoal.team = this.team.id;
      const result = await this.$http.post("/goal/team", this.teamGoal);
      if (result.status == 201) {
        this.$toast.success("Metas lançadas com sucesso!");
        this.salvarMetaSalePerson();
      } else {
        this.$toast.error("Error ao lançar meta!");
      }
    },

    async salvarMetaSalePerson() {
      const result = await this.$http.post(
        "/goal/salerperson/bulk",
        this.salesPersons
      );
      if (result.status == 201) {
        this.$toast.success("Metas dos vendedores lançadas com sucesso!");
      } else {
        this.$toast.error("Error ao lançar meta!");
      }
    },
    getColor(val) {
      if (!val <= 0) return "success";
      else return "error";
    }
  }
};
</script>

<style>
.customHeader {
  font-size: 1rem !important;
}
.linkPeriod {
  text-decoration: none;
}
.linkPeriod:link {
  color: #000;
}
.linkPeriod:visited {
  color: #cccc;
}
</style>